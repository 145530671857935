import { BrandedIcon, Button, Icon } from '@lk/lk-design-system'
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useCtaUtils from '../../hooks/useCtaUtils'
import useIsLoaded from '../../hooks/useIsLoaded'
import useSeoTelemetry, { GmtFunnelNames, GtmEvents, TagManagerDataLayerItem } from '../../hooks/useSeoTelemetry'
import * as SanitySchema from '../../lib/types/sanity-schema'
import { isSameTargetUrl } from '../../lib/urlUtils'
import CTALink from '../CTALink/CTALink'
import DecisionTreeBranchCard from './DecisionTreeBranchCard'
import DecisionTreeLeaveCard from './DecisionTreeLeaveCard'
import DecisionTreeProgress from './DecisionTreeProgress'
import { findNodeInPath, findParentNode, getLeaveLinks, TreeLeaveLink } from './DecisionTreeUtils'

export type DecisionTreeChoicesProps = Omit<SanitySchema.DecisionTree, '_type'> & {
  onRestartWizard: () => void
}

const removeDuplicates = (value, index, array) => array.indexOf(value) === index

const DecisionTreeChoices: FC<DecisionTreeChoicesProps> = ({
  title,
  question,
  showDetail,
  detailTitle,
  nodes,
  onRestartWizard,
  decisionTreeDataLayer,
}) => {
  const { t } = useTranslation()
  const { isLoaded } = useIsLoaded()
  const { resolveCtaReferences } = useCtaUtils()
  const { pushToGoogleTagManager } = useSeoTelemetry()
  const [currentKey, setCurrentKey] = useState('')
  const [isFinal, setIsFinal] = useState(false)
  const [currentNodes, setCurrentNodes] = useState(nodes)
  const [currentQuestion, setCurrentQuestion] = useState(question)
  const funnelStepRef = useRef(1)

  const [linkGroups, setLinkGroups] = useReducer(
    (_acc, curr) => curr.map((group) => group?.toLowerCase()).filter(removeDuplicates),
    getLeaveLinks(currentKey, nodes)
      .map((l) => l.group.toLowerCase())
      .filter(removeDuplicates),
  )

  const searchCurrentLinks = (_actual: TreeLeaveLink[], curr: TreeLeaveLink[]) => {
    const newLinks = []
    for (let i = 0; i < curr.length; i += 1) {
      const savedLink = newLinks.find((link) => isSameTargetUrl(link.cta, curr[i].cta))
      if (!savedLink) {
        newLinks.push({ ...curr[i], group: curr[i].group?.toLowerCase() })
      } else {
        savedLink.active = savedLink.active || curr[i].active
      }
    }
    return newLinks.map((link) => ({ ...link, cta: resolveCtaReferences(link.cta) }))
  }

  const [currentLinks, setCurrentLinks] = useReducer(
    searchCurrentLinks,
    searchCurrentLinks([], getLeaveLinks(currentKey, nodes)),
  )

  const onBack = useCallback(() => {
    const parent = findParentNode(currentKey, nodes)
    setCurrentKey(parent?._key)
    funnelStepRef.current += 1
    if (!parent) {
      funnelStepRef.current = 1
      onRestartWizard()
    }
  }, [currentKey, nodes, onRestartWizard])

  const onRestart = () => {
    setCurrentKey('')
    onRestartWizard()
    funnelStepRef.current = 1
  }

  useEffect(() => {
    const newNode = findNodeInPath(currentKey, nodes)
    const childNodes = newNode ? newNode.nodes : nodes
    setCurrentNodes(childNodes)
    const isLast = childNodes.find((node) => node._type === 'decisionTreeLeave')
    setIsFinal(isLast)
    if (isLast) {
      setCurrentQuestion(t('decisionTree.lastStep'))
    } else {
      const newQuestion = newNode ? newNode.question : question
      setCurrentQuestion(newQuestion)
    }
    if (showDetail) {
      const newLinks = getLeaveLinks(currentKey, nodes)
      setCurrentLinks(newLinks)
      setLinkGroups(newLinks.map((l) => l.group))
    }
  }, [currentKey, nodes, question, showDetail, t])

  const getFunnelStep = (step: number) => `step_${String(step).padStart(2, '0')}`

  const createDataLayerPayload = (baseData: object, additionalData: object = {}) => {
    if (!decisionTreeDataLayer || !decisionTreeDataLayer.dataLayer) return null
    return {
      ...JSON.parse(decisionTreeDataLayer.dataLayer),
      ...baseData,
      ...additionalData,
    }
  }

  const pushDataLayerEvent = (eventName: GtmEvents, data: TagManagerDataLayerItem) => {
    pushToGoogleTagManager({
      event: eventName,
      data,
      sendUserData: decisionTreeDataLayer?.userData,
      pageLocation: decisionTreeDataLayer?.pageLocation,
    })
  }

  const sendAnswerDataLayer = (node: SanitySchema.DecisionTreeBranch, key: string) => {
    setCurrentKey(key)

    const dataToSend = createDataLayerPayload({
      funnel_name: currentQuestion,
      funnel_step: getFunnelStep(funnelStepRef.current),
      respuesta_pregunta: node.shortcutTitle,
    })

    if (dataToSend) {
      pushDataLayerEvent(GtmEvents.SIMULATOR_PROCESS, dataToSend)
      funnelStepRef.current += 1
    }
  }

  const sendLoadedLastNodeDataLayer = (productInformation: object) => {
    const dataToSend = createDataLayerPayload(
      {
        funnel_name: GmtFunnelNames.SIMULATOR_FINISHED,
        funnel_step: getFunnelStep(funnelStepRef.current),
      },
      productInformation,
    )
    if (dataToSend) {
      pushDataLayerEvent(GtmEvents.SIMULATOR_COMPLETED, dataToSend)
      funnelStepRef.current += 1
    }
  }

  const sendSelectedCtaDataLayer = (productInformation: object) => {
    const dataToSend = createDataLayerPayload(
      {
        funnel_name: currentQuestion,
        funnel_step: getFunnelStep(funnelStepRef.current),
      },
      productInformation,
    )
    if (dataToSend) {
      pushDataLayerEvent(GtmEvents.SIMULATOR_PRODUCT_SELECTED, dataToSend)
      funnelStepRef.current += 1
    }
  }

  useEffect(() => {
    if (isFinal) {
      sendLoadedLastNodeDataLayer({})
    }
  }, [isFinal])

  return (
    <div className="decisionTree-choice">
      <p className="decisionTree-title">{title}</p>
      <div className="decisionTree-box">
        <div className="decisionTree-header">
          <Button
            onClick={onBack}
            size="sm"
            label={t('decisionTree.previous')}
            buttonRole="secondary"
            className="decisionTree-back"
          />
          <DecisionTreeProgress currentKey={currentKey} nodes={nodes} />
        </div>
        <div className="decisionTree-question ">{currentQuestion}</div>
        <div className="decisionTree-paths">
          {currentNodes &&
            currentNodes.map((node) =>
              node._type === 'decisionTreeBranch' ? (
                <DecisionTreeBranchCard
                  node={node}
                  key={node._key}
                  onSelected={() => sendAnswerDataLayer(node, node._key)}
                />
              ) : (
                <DecisionTreeLeaveCard node={node} key={node._key} onClickCallback={sendSelectedCtaDataLayer} />
              ),
            )}
        </div>
        {isFinal && (
          <div className="decisionTree-footer">
            <Button
              onClick={onRestart}
              size="sm"
              label={t('decisionTree.restart')}
              buttonRole="secondary"
              className="decisionTree-restart"
            />
          </div>
        )}
        {(!isLoaded || (showDetail && !isFinal)) && (
          <div className="decisionTree-links">
            <div className="decisionTree-links__title">
              <BrandedIcon name="lightbulb" size={32} /> {detailTitle}
            </div>
            <div className="decisionTree-links__groups">
              {linkGroups.map((group) => (
                <div key={`decisionTreeGroupLink-${group}`} className="decisionTree-links__group">
                  <div className="decisionTree-links__group-title">{group}</div>
                  <ul className="decisionTree-links__group-links">
                    {currentLinks
                      .filter((l) => l.group === group)
                      .sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map((link) => (
                        <li
                          key={link._key}
                          className={`decisionTree-links__link ${link.active ? 'decisionTree-links__link_active' : ''}`}
                        >
                          <CTALink
                            title={link.title}
                            pageRoute={link.cta.pageRoute}
                            link={link.cta.link}
                            route={link.cta.route}
                            onClickCallBack={() =>
                              sendSelectedCtaDataLayer({
                                product_name: link.title,
                                ...(link.productInformation ? JSON.parse(link.productInformation) : {}),
                              })
                            }
                          />
                          <Icon name="chevron-right" size={24} />
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DecisionTreeChoices
