import '../Widgets.scss'
import './WidgetTravelInsurance.scss'

import { Button } from '@lk/lk-design-system'
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useSeoTelemetry, { GtmEvents } from '../../../../hooks/useSeoTelemetry'
import { DataLayer } from '../../../../lib/types/sanity-schema'
import LKApis from '../../../../services/lk-apis/LKApisProvider'
import {
  Country,
  Coverage,
  PatchTravelProposalArgs,
  PostTravelProposalArgs,
  TravelOffer,
} from '../../../../services/lk-apis/types'
import AccessibleModal from '../../../AccessibleModal/AccessibleModal'
import { DocWithSlug } from '../../../CTALink/CTALink'
import LoadingIndicator from '../../../LoadingIndicator/LoadingIndicator'
import widgetTravelInsuranceEvents, { insuranceChoicesNames } from './constants/widgetTravelInsuranceEvents'
import TravelInsuranceOfferBox from './steps/TravelInsuranceChoiceBox'
import TravelInsuranceChoices from './steps/TravelInsuranceChoices'
import TravelInsuranceFinalForm from './steps/TravelInsuranceFinalForm'
import TravelInsuranceInitialForm from './steps/TravelInsuranceInitialForm'

export interface WidgetTravelInsuranceProps {
  legalLink: DocWithSlug
  lkWidgetsDataLayer: DataLayer
}

const WidgetTravelInsurance: FC<WidgetTravelInsuranceProps> = ({ legalLink, lkWidgetsDataLayer }) => {
  const { t } = useTranslation()
  const { lkWebApi } = useContext(LKApis)
  const { pushToGoogleTagManager } = useSeoTelemetry()
  const [loading, setLoading] = useState(false)
  const [sentOk, setSentOk] = useState(false)
  const [step, setStep] = useState(0)
  const [travelInfo, setTravelInfo] = useState<PostTravelProposalArgs>(null)
  const [travelProposalId, setTravelProposalId] = useState<string>(null)
  const [insuranceChoices, setInsuranceChoices] = useState<TravelOffer[]>([])
  const [selectedChoice, setSelectedChoice] = useState<TravelOffer>(null)
  const countries = useRef<Country[]>([])
  const coverages = useRef<Coverage[]>([])
  const [apiError, setApiError] = useState(null)

  const createBaseDataLayer = (info: PostTravelProposalArgs, choice = undefined, additionalData = {}) => ({
    fecha_inicio: info.startDate.toISOString().split('T')[0],
    fecha_vencimiento: info.endDate.toISOString().split('T')[0],
    pais_destino: countries.current.find((currentCountry) => currentCountry.code === info.countryCode)?.name,
    numero_asegurados: info.travelerCount,
    cobertura_cancelacion: coverages.current.find((currentCoverage) => currentCoverage.id === info.coverageCode)?.name,
    ...(choice ? { cuota_seguro: choice.amount.amount.toString(), currency: choice.amount.currency } : {}),
    ...additionalData,
  })

  const sendDataLayer = (event, stepData = {}, extraData = {}) => {
    const dataLayer = {
      event,
      data: {
        ...widgetTravelInsuranceEvents.general,
        ...stepData,
        ...(extraData || {}),
        ...(lkWidgetsDataLayer?.dataLayer ? JSON.parse(lkWidgetsDataLayer.dataLayer) : {}),
      },
      sendUserData: lkWidgetsDataLayer?.userData ?? false,
      pageLocation: lkWidgetsDataLayer?.pageLocation ?? false,
    }

    pushToGoogleTagManager(dataLayer)
  }

  const sendErrorDataLayer = (errorMessage) => {
    if (!errorMessage) return
    sendDataLayer(GtmEvents.SIMULATOR_ERROR_MESSAGE, {}, { simulator_error: errorMessage })
  }

  const sendCalculateDataLayer = (initialData) => {
    const simulatorData = createBaseDataLayer(initialData)
    sendDataLayer(GtmEvents.SIMULATOR_PROCESS, widgetTravelInsuranceEvents.step_01, simulatorData)
  }

  const sendResultDataLayer = (initialData) => {
    const offerDataLayer = createBaseDataLayer(initialData)
    sendDataLayer(GtmEvents.SIMULATOR_COMPLETED, widgetTravelInsuranceEvents.step_02, offerDataLayer)
  }

  const sendProductSelectedDataLayer = (choice) => {
    const productSubproduct = insuranceChoicesNames[insuranceChoices.indexOf(choice)]
    const selectedOfferDataLayer = createBaseDataLayer(travelInfo, choice, { product_subproduct: productSubproduct })
    sendDataLayer(GtmEvents.SIMULATOR_PRODUCT_SELECTED, widgetTravelInsuranceEvents.step_03, selectedOfferDataLayer)
  }

  const sendGenerateLeadDataLayer = (finalData, status) => {
    const generateLeadDataLayer = createBaseDataLayer(travelInfo, selectedChoice, {
      value: finalData.amount,
      email: finalData.email,
      phone_number: finalData.mobile.replace(' ', ''),
      status,
      product_subproduct: finalData.product_subproduct,
    })
    sendDataLayer(GtmEvents.GENERATE_LEAD, widgetTravelInsuranceEvents.step_04, generateLeadDataLayer)
  }

  const nextStep = useCallback(() => {
    setStep(step + 1)
  }, [step])

  const onInitialFormSubmit = useCallback(
    async (initialData: PostTravelProposalArgs) => {
      setLoading(true)
      setTravelInfo(initialData)
      sendCalculateDataLayer(initialData)
      await lkWebApi
        .postTravelProposal(initialData)
        .then(({ data }) => {
          const { travelProposalID, travelOffers } = data.data
          setTravelProposalId(travelProposalID)
          setInsuranceChoices(travelOffers)
          sendResultDataLayer(initialData)
          nextStep()
        })
        .catch(() => {
          const error = t('widgetTravelInsurance.errors.genericError')
          setApiError(error)
          sendErrorDataLayer(error)
        })
        .finally(() => setLoading(false))
    },
    [lkWebApi, nextStep, t],
  )

  const onChoiceSelected = useCallback(
    (choice: TravelOffer) => {
      setSelectedChoice(choice)
      sendProductSelectedDataLayer(choice)
      nextStep()
    },
    [nextStep],
  )

  const onFinalFormSubmit = useCallback(
    async (finalData: PatchTravelProposalArgs) => {
      setLoading(true)
      const productSubproduct = insuranceChoicesNames[insuranceChoices.indexOf(selectedChoice)]
      const finalDataInformation = {
        ...finalData,
        amount: selectedChoice?.amount.amount.toString(),
        product_subproduct: productSubproduct,
      }
      await lkWebApi
        .patchTravelProposal(travelProposalId, finalData)
        .then(() => {
          sendGenerateLeadDataLayer(finalDataInformation, widgetTravelInsuranceEvents.status.ok)
          setSentOk(true)
        })
        .catch(() => {
          const error = t('widgetTravelInsurance.errors.genericError')
          setApiError(error)
          sendGenerateLeadDataLayer(finalDataInformation, widgetTravelInsuranceEvents.status.ko)
          sendErrorDataLayer(error)
        })
        .finally(() => setLoading(false))
    },
    [lkWebApi, travelProposalId, t, selectedChoice],
  )

  useEffect(() => {
    setLoading(true)
    const fetchCountries = async () => {
      try {
        const { data } = await lkWebApi.getTravelCountries()
        countries.current = data?.data?.countries || []
      } catch (e) {
        setApiError(e.message)
        sendErrorDataLayer(e.message)
      }
    }
    const fetchCoverages = async () => {
      try {
        const { data } = await lkWebApi.getTravelCoverages()
        coverages.current = data?.data?.coverages || []
      } catch (e) {
        setApiError(e.message)
        sendErrorDataLayer(e.message)
      }
    }
    Promise.all([fetchCountries(), fetchCoverages()])
      .then(() => setLoading(false))
      .catch((err) => console.error('Error loading initial data:', err))
  }, [lkWebApi])

  return (
    <div className="lk-calculator lk-widget-travel" data-testid="lk-widget-travel-insurance" aria-busy={loading}>
      <div className="lk-calculator__container row">
        <div className="lk-calculator__main col-md-12 p-0">
          <div className="lk-calculator__content">
            <div className="lk-calculator__intro">
              <div className="lk-calculator__title">{t('widgetTravelInsurance.title')}</div>
              <div className="lk-calculator__intro">
                <p className="strong">{t('widgetTravelInsurance.subtitle')}</p>
              </div>
              {step > 0 && (
                <Button
                  onClick={() => setStep(step - 1)}
                  size="sm"
                  icon="chevron-left"
                  label={t('calculators.previous')}
                  buttonRole="secondary"
                  className="lk-widget-travel__back"
                />
              )}
            </div>
            <div className="lk-calculator__choices">
              <LoadingIndicator loading={loading} />
              {step === 0 && (
                <TravelInsuranceInitialForm
                  countries={countries.current}
                  coverages={coverages.current}
                  initialValues={travelInfo}
                  onSubmit={onInitialFormSubmit}
                  sendErrorDataLayer={sendErrorDataLayer}
                />
              )}
              {step === 1 && <TravelInsuranceChoices offers={insuranceChoices} onChoiceSelected={onChoiceSelected} />}
              {step === 2 && (
                <div className="row">
                  <div className="col-md-4 lk-widget-travel__final bg-berenjena-claro-4">
                    <TravelInsuranceOfferBox offer={selectedChoice} showButton={false} />
                  </div>
                  <div className="col-md-8">
                    <TravelInsuranceFinalForm
                      travelOffer={selectedChoice}
                      legalLink={legalLink}
                      onSubmit={onFinalFormSubmit}
                      sendErrorDataLayer={sendErrorDataLayer}
                    />
                  </div>
                </div>
              )}
            </div>
            <p
              className="lk-widget-travel__footer"
              dangerouslySetInnerHTML={{ __html: t('widgetTravelInsurance.wantMoreInfo') }}
            />
          </div>
        </div>
      </div>
      <AccessibleModal
        heading={t('widgetTravelInsurance.successTitle')}
        active={sentOk}
        onClose={() => {
          setSentOk(false)
          setStep(0)
          setInsuranceChoices([])
          setSelectedChoice(null)
        }}
        fixed
      >
        <p>{t('widgetTravelInsurance.successMessage')}</p>
      </AccessibleModal>
      <AccessibleModal
        heading={t('widgetTravelInsurance.errors.apiErrorTitle')}
        active={!!apiError}
        onClose={() => {
          setApiError(null)
        }}
        fixed
      >
        <div>{apiError}</div>
      </AccessibleModal>
    </div>
  )
}

export default WidgetTravelInsurance
