import './WidgetCustomForm.scss'

import { Button } from '@lk/lk-design-system'
import React, { FC, FormEvent, useCallback, useContext, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'
import { useTranslation } from 'react-i18next'

import useSeoTelemetry from '../../../../hooks/useSeoTelemetry'
import { getReCaptchaKeyLkConfig } from '../../../../lib/lkWebConfig'
import SanitySchema, { SanityReference } from '../../../../lib/types/sanity-schema'
import LKApis from '../../../../services/lk-apis/LKApisProvider'
import AccessibleModal from '../../../AccessibleModal/AccessibleModal'
import PortableText from '../../../PortableText/PortableText'
import { TextWithIllustrationBuilder } from '../../../TextWithIllustration/TextWithIllustration'
import allowedKeys, { widgetCustomFormEvents } from './constants/widgetCustomFormEvents'
import useGetCaptchaToken from './useGetCaptchaToken'
import CustomFormField from './WidgetCustomFormField'

interface CustomFormProps {
  form: SanityReference<SanitySchema.Form>
}

enum FormStatus {
  'OK',
  'ERROR',
  'ERROR_DUPLICATED',
  null,
}

const CustomForm: FC<CustomFormProps> = ({ form }) => {
  const { lkWebApi } = useContext(LKApis)
  const captchaRef = React.useRef<ReCAPTCHA>()
  const siteKey = getReCaptchaKeyLkConfig()
  const getCaptchaToken = useGetCaptchaToken()
  const formRef = React.useRef<HTMLFormElement>(null)
  const { pushToGoogleTagManager } = useSeoTelemetry()

  const [responseStatus, setResponseStatus] = useState<FormStatus>()

  const [data, setData] = useState(
    form.formFields.reduce((acc: Record<string, string | null>, field) => {
      acc[field.fieldName] = null
      return acc
    }, {}),
  )

  const onHandleChange = useCallback(
    (e) => {
      e.persist()
      const { name, value, checked, type } = e.target

      setData((prevState) => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }))
    },
    [setData],
  )

  const onHandleLegal = useCallback(
    (name, legalAccepted) => {
      setData((prevState) => ({
        ...prevState,
        [name]: legalAccepted,
      }))
    },
    [setData],
  )

  const { t } = useTranslation()

  const getKeyCode = () => {
    const fieldsWithKeyCode = form.formFields.filter((f) => f.isKeyCode)
    const keyCodes = fieldsWithKeyCode?.map(({ fieldName }) => data[fieldName]) || []

    return keyCodes.join('#') || undefined
  }

  const convertDataToString = () =>
    JSON.stringify(
      Object.entries(data).reduce(
        (acc, [key, value]) => {
          if (value !== null) {
            acc[key] = value
          }
          return acc
        },
        { Referer: window?.location?.toString() },
      ),
    )

  const parseQueryParams = (queryString: string | undefined): Record<string, string> => {
    if (!queryString) return {}
    return queryString.split('&').reduce((acc: Record<string, string>, param: string) => {
      const [key, value] = param.split('=')
      if (!allowedKeys.includes(key)) return acc
      acc[decodeURIComponent(key)] = decodeURIComponent(value || '')
      return acc
    }, {})
  }

  const handleDataLayerPush = (extraData) => {
    if (
      !form?.formDataLayer &&
      !form?.formDataLayer?.dataLayer &&
      !form?.formDataLayer?.userData &&
      !form?.formDataLayer?.pageLocation
    ) {
      return
    }
    const params = window?.location?.search?.slice(1)
    const paramsObject = parseQueryParams(params)
    const { referrer } = document
    const dataToPush = {
      ...widgetCustomFormEvents.general,
      ...extraData,
      ...(referrer ? { page_origin: referrer } : {}),
      ...(form?.formDataLayer?.dataLayer ? JSON.parse(form.formDataLayer.dataLayer) : {}),
      ...paramsObject,
    }
    if (Object.keys(dataToPush).length !== 0) {
      pushToGoogleTagManager({
        data: dataToPush,
        sendUserData: form?.formDataLayer?.userData,
        pageLocation: form?.formDataLayer?.pageLocation,
      })
    }
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      const token = await getCaptchaToken(captchaRef)
      const response = await lkWebApi.postCustomForm(
        {
          formId: form.formId,
          keyCode: getKeyCode(),
          sendMail: form.sendMail,
          title: form.mailTitle,
          subtitle: form.mailSubtitle,
          mailList: form.mailList?.alias,
          data: convertDataToString(),
        },
        token,
      )
      if (response?.data?.succeeded) handleDataLayerPush({ status: widgetCustomFormEvents.status.ok })
      setResponseStatus(FormStatus.OK)
    } catch (err) {
      handleDataLayerPush({ status: widgetCustomFormEvents.status.ko })
      const errorInfo = err.response?.data?.errors
      if (errorInfo?.status === 422 && errorInfo?.detail.match(/KeyCode repetido/)) {
        setResponseStatus(FormStatus.ERROR_DUPLICATED)
      } else {
        setResponseStatus(FormStatus.ERROR)
      }
    }
  }

  return (
    <>
      <AccessibleModal
        heading=""
        active={responseStatus === FormStatus.ERROR}
        onClose={() => setResponseStatus(null)}
        fixed
      >
        {form.error ? <PortableText blocks={form.error} /> : <p>{t('customForm.modal.error.body')}</p>}
      </AccessibleModal>
      <AccessibleModal
        heading=""
        active={responseStatus === FormStatus.ERROR_DUPLICATED}
        onClose={() => setResponseStatus(null)}
        fixed
      >
        <p>{t('customForm.modal.duplicatedError.body')}</p>
      </AccessibleModal>

      <AccessibleModal
        heading=""
        active={responseStatus === FormStatus.OK}
        onClose={() => {
          setResponseStatus(null)
          setData({})
          formRef.current.reset()
        }}
        fixed
      >
        {form.success ? <PortableText blocks={form.success} /> : <p>{t('customForm.modal.success.body')}</p>}
      </AccessibleModal>
      {form.showTitle && (
        <>
          <div className="h4">{form.title}</div>
          <p>{form.subtitle}</p>
        </>
      )}
      <form onSubmit={onSubmit} className="lk-custom-form" data-testid="custom-form" ref={formRef}>
        {form.formFields.map((field) => {
          const onClick = field.type === 'checkbox' ? onHandleChange : () => {}
          return (
            <div className="col-lg-12 col-md-12 col-sm-12" key={field._key}>
              {field._type === 'formField' && (
                <CustomFormField
                  id={field._key}
                  onInput={onHandleChange}
                  onClick={onClick}
                  onLegalChange={onHandleLegal}
                  value={field.type === 'checkbox' ? field.value : data[field.fieldName]}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                />
              )}
              {field._type === 'textWithIllustration' && (
                <TextWithIllustrationBuilder text={field.text} illustration={field.illustration} />
              )}
            </div>
          )
        })}
        <div className="lk-custom-form__submit">
          <Button label={form.submitLabel || t('customForm.sendForm')} type="submit" role="button" />
        </div>
        <ReCAPTCHA ref={captchaRef} sitekey={siteKey} size="invisible" />
        <p
          className="text-xsmall lk-custom-form__captcha"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t('customForm.captchaTerms'),
          }}
        />
      </form>
    </>
  )
}

export default CustomForm
