import '../Widgets.scss'

import { Input } from '@lk/lk-design-system'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import React, { FC, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GtmEvents } from '../../../../hooks/useSeoTelemetry'
import * as SanitySchema from '../../../../lib/types/sanity-schema'
import CTALink from '../../../CTALink/CTALink'
import multiinsuranceEvents from './constants/multinsuranceEvents'

interface MultiInsuranceProps {
  cta: SanitySchema.Cta
}

const WidgetMultiInsurance: FC<MultiInsuranceProps> = ({ cta }) => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()

  const carInsuranceRef = useRef(null)
  const homeInsuranceRef = useRef(null)
  const lifeInsuranceRef = useRef(null)
  const otherInsuranceRef = useRef(null)

  const [amount, setAmount] = useState('0')

  const refToInt = (ref) => parseInt(ref.current.value, 10) || 0

  const calculateMultiInsuranceAmount = useCallback(() => {
    const insuranceTotalAmount =
      refToInt(carInsuranceRef) + refToInt(homeInsuranceRef) + refToInt(lifeInsuranceRef) + refToInt(otherInsuranceRef)
    const result = insuranceTotalAmount >= 0 ? insuranceTotalAmount / 12 : 0
    const roundedResult = Math.round(result * 100) / 100

    setAmount(roundedResult.toLocaleString('es-ES'))
  }, [])

  const clearInput = useCallback((e) => {
    if (e.target.value === '0') {
      e.target.value = ''
    }
  }, [])

  const onlyNumbersValidator = useCallback((e) => {
    const valueAsNumber = parseInt((e?.target as HTMLInputElement)?.value, 10)
    e.target.value = valueAsNumber < 0 ? '0' : valueAsNumber.toString()
  }, [])

  const addMultinsuranceInformation = () => {
    if (!cta.ctaDataLayer || !cta.ctaDataLayer.dataLayer) {
      return undefined
    }
    return {
      ...cta.ctaDataLayer,
      dataLayer: JSON.stringify({
        event: GtmEvents.SIMULATOR_COMPLETED_CTA_CONTRATAR,
        cuota_seguro: amount,
        ...multiinsuranceEvents.step_01,
        ...(cta?.ctaDataLayer?.dataLayer ? JSON.parse(cta.ctaDataLayer.dataLayer) : {}),
      }),
    }
  }

  return (
    <div className="lk-calculator" data-testid="lk-widget-multi-insurance">
      <div className="lk-calculator__container row">
        <div className="lk-calculator__main col-lg-8 col-md-12 p-0">
          <div className="lk-calculator__content">
            <div className="lk-calculator__intro">
              <h2 className="lk-calculator__title">{t('widgetMultiInsurance.title')}</h2>
            </div>
            <p className="strong">{t('widgetMultiInsurance.description')}</p>
            <div className="lk-calculator__options">
              <div className="row">
                <div className="col-xs-4 as-center">
                  <p className="strong lk-calculator__options-desc">{t('widgetMultiInsurance.carInsurance')}</p>
                </div>
                <div className="col-xs-4 p-0">
                  <p className="lk-calculator__options-title">{t('widgetMultiInsurance.insuranceQuantity')}</p>
                  <Input
                    type="number"
                    onFocus={clearInput}
                    defaultValue={0}
                    min={0}
                    data-testid="lk-input-car-insurance-count"
                    aria-label={t('widgetMultiInsurance.numberOfCarInsurances')}
                    onKeyUp={onlyNumbersValidator}
                  />
                </div>
                <div className="col-xs-4 p-0">
                  <p className="lk-calculator__options-title">{t('widgetMultiInsurance.totalInsuranceAmount')}</p>
                  <Input
                    append="€"
                    type="number"
                    onFocus={clearInput}
                    forwardRef={carInsuranceRef}
                    defaultValue={0}
                    min={0}
                    onBlur={calculateMultiInsuranceAmount}
                    data-testid="lk-input-car-insurance"
                    aria-label={t('widgetMultiInsurance.totalCarInsurances')}
                    onKeyUp={onlyNumbersValidator}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-4">
                  <p className="strong lk-calculator__options-desc">{t('widgetMultiInsurance.homeInsurance')}</p>
                </div>
                <div className="col-xs-4 p-0">
                  <Input
                    type="number"
                    onFocus={clearInput}
                    defaultValue={0}
                    min={0}
                    data-testid="lk-input-home-insurance-count"
                    aria-label={t('widgetMultiInsurance.numberOfHomeInsurances')}
                    onKeyUp={onlyNumbersValidator}
                  />
                </div>
                <div className="col-xs-4 p-0">
                  <Input
                    append="€"
                    type="number"
                    onFocus={clearInput}
                    forwardRef={homeInsuranceRef}
                    defaultValue={0}
                    min={0}
                    onBlur={calculateMultiInsuranceAmount}
                    data-testid="lk-input-home-insurance"
                    aria-label={t('widgetMultiInsurance.totalHomeInsurances')}
                    onKeyUp={onlyNumbersValidator}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-4">
                  <p className="strong lk-calculator__options-desc">{t('widgetMultiInsurance.lifeInsurance')}</p>
                </div>
                <div className="col-xs-4 p-0">
                  <Input
                    type="number"
                    onFocus={clearInput}
                    defaultValue={0}
                    min={0}
                    data-testid="lk-input-life-insurance-count"
                    aria-label={t('widgetMultiInsurance.numberOfLifeInsurances')}
                    onKeyUp={onlyNumbersValidator}
                  />
                </div>
                <div className="col-xs-4 p-0">
                  <Input
                    append="€"
                    type="number"
                    onFocus={clearInput}
                    forwardRef={lifeInsuranceRef}
                    defaultValue={0}
                    min={0}
                    onBlur={calculateMultiInsuranceAmount}
                    data-testid="lk-input-life-insurance"
                    aria-label={t('widgetMultiInsurance.totalLifeInsurances')}
                    onKeyUp={onlyNumbersValidator}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-4">
                  <p className="strong lk-calculator__options-desc">{t('widgetMultiInsurance.otherInsurance')}</p>
                </div>
                <div className="col-xs-4 p-0">
                  <Input
                    type="number"
                    onFocus={clearInput}
                    defaultValue={0}
                    min={0}
                    data-testid="lk-input-other-insurance-count"
                    aria-label={t('widgetMultiInsurance.numberOfOtherInsurances')}
                    onKeyUp={onlyNumbersValidator}
                  />
                </div>
                <div className="col-xs-4 p-0">
                  <Input
                    append="€"
                    type="number"
                    onFocus={clearInput}
                    forwardRef={otherInsuranceRef}
                    defaultValue={0}
                    min={0}
                    onBlur={calculateMultiInsuranceAmount}
                    data-testid="lk-input-other-insurance"
                    aria-label={t('widgetMultiInsurance.totalOtherInsurances')}
                    onKeyUp={onlyNumbersValidator}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lk-calculator__aside col-lg-4 col-md-12 bg-berenjena-claro-4 p-0">
          <div className="lk-calculator__content">
            <p className="lk-calculator__highlighted-text strong m-0">{t('widgetMultiInsurance.mensualAmount')}</p>
            <div className="d-flex jc-space-between">
              <div className="lk-calculator__result">
                <p className="lk-calculator__quantity strong" data-testid="lk-calculator-quantity">
                  {amount}
                </p>
                <p className="lk-calculator__currency">{t('widgetMultiInsurance.currencyPerMonth')}</p>{' '}
              </div>
              {breakpoints.sm && (
                <CTALink
                  kind={cta.kind}
                  className="as-center lk-calculator__button lk-button lk-calculator__button lk-calculator__button--apply lk-button--primary"
                  route={cta.route}
                  title={cta.title}
                  pageRoute={cta.pageRoute}
                  link={cta.link}
                  ctaDataLayer={addMultinsuranceInformation()}
                />
              )}
            </div>
            <div>
              <p>
                {t('widgetMultiInsurance.tin')}: 0,00 %{' '}
                <span className="strong text-large">{t('widgetMultiInsurance.tae')}: 0,00%</span>
              </p>
            </div>
            <div className="lk-calculator__key-points">
              <div className="lk-calculator__key-point">
                <p>{t('widgetMultiInsurance.openingCommission')}</p>
                <p>0,00 €</p>
              </div>
            </div>
            <div className="lk-calculator__message">
              <p>{t('widgetMultiInsurance.helpText')}</p>
            </div>
            {!breakpoints.sm && (
              <CTALink
                kind={cta.kind}
                className="lk-calculator__button lk-button lk-calculator__button lk-calculator__button--apply lk-button--primary"
                route={cta.route}
                title={cta.title}
                pageRoute={cta.pageRoute}
                link={cta.link}
                ctaDataLayer={addMultinsuranceInformation()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WidgetMultiInsurance
