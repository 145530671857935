import './PageTemplate.scss'

import { graphql } from 'gatsby'
import React, { FC, useEffect } from 'react'

import BioCatch from '../../components/Biocatch/Biocatch'
import { BolUserProvider } from '../../components/BolUser/BolUserProvider'
import Chat from '../../components/Chat/Chat'
import { ContentFilterProvider } from '../../components/ContentFilter/ContentFilterProvider'
import Errors from '../../components/Errors/Errors'
import BreadCrumb from '../../components/Layout/Header/BreadCrumb/BreadCrumb'
import Layout from '../../components/Layout/Layout'
import LkSanityPageComponent from '../../components/LkSanityPageComponent/LkSanityPageComponent'
import SalesForce from '../../components/SalesForce/SalesForce'
import SEO from '../../components/SEO/SEO'
import useVisitedKeywords from '../../hooks/useVisitedKeywords'
import * as SanitySchema from '../../lib/types/sanity-schema'
import { LKSiteSettingsProvider } from '../../services/SiteSettingsProvider'

export const query = graphql`
  query PageTemplateQuery($id: String!, $lang: String!) {
    page: sanityPage(id: { eq: $id }) {
      slug {
        current
      }
      siteTab {
        ... on SanitySiteTab {
          _id
          route {
            slug {
              current
            }
          }
        }
      }
      language
      keywords {
        keyword
      }
      openGraph {
        title
        description
        metaTitle
        showTitle
        image {
          asset {
            path
            url
          }
        }
      }
      seoSchemas {
        type
        name
        description
      }
      pageCta {
        ...LKCtaFragment
      }
      pageCtaBol {
        ...LKCtaFragment
      }
      chat
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
    locales: allLocale(filter: { ns: { in: ["common"] }, language: { eq: $lang } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
// LOCALES

export interface PageTemplateProps {
  data: {
    page?: Omit<SanitySchema.Page, 'content' | '_type'> & {
      _type: string
      content: Array<
        | SanitySchema.SanityKeyed<SanitySchema.Section>
        | SanitySchema.SanityKeyedReference<SanitySchema.Banner>
        | SanitySchema.SanityKeyed<SanitySchema.ContentFilter>
      >
    }
    tabs?: SanitySchema.SanityReference<SanitySchema.SiteTab>
  }
  errors?: Array<{ message: string }>
  pageContext: {
    id: string
    alternativeUrls: {
      [key: string]: string
    }
    language: string
    siteSettingsId: string
  }
}

const PageTemplate: FC<PageTemplateProps> = ({
  data,
  errors,
  pageContext: { id: pageId, alternativeUrls, language, siteSettingsId },
}) => {
  const { addVisitedKeywords } = useVisitedKeywords()

  const { page } = data

  const currentSlug = page.slug.current

  const pageTitle = page.openGraph.title
  const description = page.openGraph?.description
  const { metaTitle } = page.openGraph
  const image = page.openGraph?.image?.asset?.url
  const { keywords } = page
  const isHome = page.slug.current === page.siteTab?.route?.slug?.current

  useEffect(() => {
    if (keywords) {
      addVisitedKeywords(keywords.map((k) => k.keyword))
    }
  }, [addVisitedKeywords, keywords])

  return errors && errors.length > 0 ? (
    <Errors errors={errors} />
  ) : (
    <LKSiteSettingsProvider siteSettingsId={siteSettingsId}>
      <ContentFilterProvider>
        <BolUserProvider>
          <Layout
            language={page.language}
            alternativeUrls={alternativeUrls}
            currentTabId={page.siteTab._id}
            isHome={isHome}
            pageCta={page.pageCta}
            pageCtaBol={page.pageCtaBol}
          >
            <SEO
              title={pageTitle}
              metaTitle={metaTitle}
              description={description}
              keywords={keywords}
              lang={language}
              slug={currentSlug}
              image={image}
              alternativeUrls={Object.entries(alternativeUrls).map(([lang, href]) => ({
                lang,
                href,
              }))}
              breadcrumbsSchema={!isHome}
              seoSchemas={page.seoSchemas}
              pageDataLayer={page.pageDataLayer}
            />
            {!isHome && <BreadCrumb url={page.slug.current} />}
            <div className="lk-first-heading" style={{ display: !page.openGraph.showTitle ? 'none' : 'block' }}>
              <h1 className="container page-title">{pageTitle}</h1>
            </div>
            {(page.content || []).map((component) => (
              <LkSanityPageComponent component={component} key={component._key ? component._key : component._id} />
            ))}
            <Chat chatType={page.chat} />
            <SalesForce />
            <BioCatch />
          </Layout>
        </BolUserProvider>
      </ContentFilterProvider>
    </LKSiteSettingsProvider>
  )
}

export default PageTemplate
PageTemplate.defaultProps = {
  errors: [],
}
