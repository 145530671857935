const widgetDeceaseInsuranceEvents = {
  general: {
    process_name: 'simulador_seguros_decesos',
    simulator_objective: 'lead',
    product_category: 'seguros',
    product_subcategory: 'para_ti_y_tu_familia',
    product_name: 'decesos',
    product_empresas: false,
    product_personas: true,
  },
  step_01: {
    funnelName: 'calcular_simulacion',
    funnelStep: 'step_01',
  },
  step_02: {
    funnelName: 'resultado_oferta_simulada',
    funnelStep: 'step_02',
  },
  step_03: {
    funnelName: 'generate_lead',
    funnelStep: 'step_03',
    contact_type: 'form',
    contact_category: 'form_simulator',
  },
  status: {
    ok: 'ok',
    ko: 'ko',
  },
}

export default widgetDeceaseInsuranceEvents
